import React, { useState } from "react";
import { Link } from "gatsby";
import SwiperCore, { Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

import Image from "components/Image";
import LinkInternal from "components/LinkInternal";

SwiperCore.use([Navigation]);

const ProjectSlider = ({ slides, link, lightbox }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  return (
    <>
      <Swiper
        className="lg:md:container md:mx-auto h-full"
        spaceBetween={48}
        navigation={{
          nextEl: ".swiper-next-alt",
          prevEl: ".swiper-prev-alt",
        }}
        slidesPerView={`auto`}
        loop={true}
        onSlideChange={(e) => {
          setCurrentSlide(e.realIndex);
        }}
      >
        {slides.map((slide, i) => {
          return (
            <SwiperSlide
              key={slide.id || i}
              className={`md:w-[600px!important] ${
                currentSlide !== i ? "bg-everglade-500 opacity-90" : ""
              }`}
            >
              <Link to={slide.uri} className="group">
                <div className={`${currentSlide !== i ? "grayscale" : ""}`}>
                  <figure className="w-full">
                    <Image
                      className="block w-full object-cover"
                      image={slide.featuredImage.node}
                    />
                  </figure>

                  <figcaption
                    className={`flex bg-cinnabar transition-all duration-300 group-hover:text-cinnabar group-hover:bg-white text-white py-4 px-8 ${
                      currentSlide === i ? "pr-16 " : ""
                    }`}
                  >
                    <div className="w-full ">
                      <div className="">
                        <div className="text-lg font-bold uppercase tracking-wider leading-none">
                          {slide.title}
                        </div>
                        <p className="text-sm font-light tracking-wide">
                          {slide.singleSector.detail.location}
                        </p>
                      </div>

                      <div className="mt-4 flex w-full justify-between">
                        {slide.singleSector.detail.projectType && (
                          <p className="text-sm tracking-wide">
                            {slide.singleSector.detail.projectType}
                          </p>
                        )}
                        <LinkInternal
                          className="ml-auto"
                          label="Read more"
                          target={slide.uri}
                        />
                      </div>
                    </div>
                  </figcaption>
                </div>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default ProjectSlider;
