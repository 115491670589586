import React from "react";
import { graphql } from "gatsby";

import Layout from "components/commercial/Layout";
import HeroImage from "components/HeroImage";
import NextSection from "components/NextSection";
import SingleSection from "components/SingleSection";
import TextWithSlides from "components/TextWithSlides";
import ProjectSlider from "components/ProjectSliderAlt";
import ClientLogos from "components/ClientLogos";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

export const query = graphql`
  {
    wp {
      page(id: "/commercial/our-experience/", idType: URI) {
        id
        title
        slug
        pageExperience {
          heroImage {
            ...MediaItem
          }
          pageDescription
          panelOne {
            image {
              ...MediaItem
            }
            text
            title
          }
          clientLogos {
            ...MediaItem
          }
          slidesWithText {
            ... on WP_Sector {
              id
              title
              uri
              featuredImage {
                node {
                  ...MediaItem
                }
              }
              singleSector {
                detail {
                  location
                  projectType
                }
              }
            }
          }
          slider {
            images {
              ...MediaItem
            }
            description
            title
          }
          nextSection {
            number
            label
            heading
            link {
              ... on WP_Page {
                id
                uri
              }
            }
            image {
              ...MediaItem
            }
          }
        }
      }
    }
  }
`;

const Experience = ({ data }) => {
  const { title } = data.wp.page;
  const {
    pageExperience: { clientLogos },
  } = data.wp.page;
  const fields = data.wp.page.pageExperience;
  const textSlides = fields.slidesWithText;
  const desc = fields.pageDescription;
  return (
    <Layout title={title}>
      <main>
        <div className="relative h-[calc(100vh - 92px)] md:h-[75vh] overflow-y-hidden">
          <h1 className="sr-only">{title}</h1>
          <div className="absolute top-0 left-0 bottom-0 z-0 h-full w-full md:w-7/12 lg:w-[45%] xl:w-7/12 bg-everglade"></div>
          {fields.heroImage && (
            <div className="md:container md:mx-auto pt-20 md:pt-32">
              <HeroImage
                number={3}
                label={title}
                description={desc}
                image={fields.heroImage}
                className={`top-[-8px]`}
              />
            </div>
          )}
        </div>
        {fields.slider && (
          <div className="py-16">
            <TextWithSlides
              slides={fields.slider.images}
              title={fields.slider.title}
              description={fields.slider.description}
            />
          </div>
        )}
        <SingleSection
          image={fields.panelOne.image}
          description={fields.panelOne.text}
          className={`my-16`}
          descriptionClass={`space-y-4`}
        />
        {clientLogos && (
          <div className="md:container mx-auto py-16 ">
            <ClientLogos logos={clientLogos} />
          </div>
        )}
        {textSlides && (
          <div className="py-12 md:py-20 bg-bobalife">
            <div className="md:container px-6 lg:px-0 md:mx-auto">
              <div className="text-everglade text-3xl uppercase font-bold tracking-wider mb-4 md:mb-8 relative">
                Take a look at some of
                <br /> our latest projects
                <div className="absolute z-20 top-12 right-[-75px] md:-top-4 md:right-[-89px] lg:left-[600px] lg:right-auto transform -translate-x-full flex flex-col border-l border-everglade">
                  <div
                    className={`swiper-prev-alt text-everglade hover:text-cinnabar cursor-pointer w-14 h-[53px] grid place-items-center font-bold text-3xl`}
                  >
                    <BsChevronLeft />
                  </div>
                  <div
                    className={`swiper-next-alt text-everglade hover:text-cinnabar cursor-pointer w-14 h-[53px] grid place-items-center font-bold text-3xl`}
                  >
                    <BsChevronRight />
                  </div>
                </div>
              </div>
              <div className="">
                <ProjectSlider slides={textSlides} />
              </div>
            </div>
          </div>
        )}
        {fields.nextSection && (
          <NextSection
            background="white"
            number={fields.nextSection.number}
            label={fields.nextSection.label}
            heading={fields.nextSection.heading}
            link={fields.nextSection.link.uri}
            image={
              fields.nextSection.image
                ? fields.nextSection.image
                : fields.heroImage
            }
          />
        )}
      </main>
    </Layout>
  );
};

export default Experience;
