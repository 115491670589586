import React from "react";
import Slider from "components/Slider";
import Image from "components/Image";

const TextWithSlides = ({ slides, title, description }) => {
  return (
    <div className="lg:container lg:mx-auto">
      <div className="mr-[calc(-50vw+50%)]">
        <div className="flex flex-col lg:flex-row items-center px-6 lg:px-0">
          <div className="w-full lg:w-2/6 space-y-4 text-everglade-500">
            <div className="font-bold text-2xl uppercase">{title}</div>
            <div
              className="space-y-4 font-light"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          </div>
          <div className="mt-8 lg:mt-0 w-full lg:w-4/6 lg:ml-16 lg:ml-30">
            {slides && slides.length > 1 ? (
              <Slider slides={slides} />
            ) : (
              <Image
                image={slides[0]}
                className={`w-80 h-80 lg:w-[500px] lg:h-[500px] octo object-cover`}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextWithSlides;
