import React from "react";
import SwiperCore, { Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

import Image from "components/Image";

SwiperCore.use([Navigation]);

const ClientLogos = ({ logos }) => {
  return (
    <>
      <div className="items-center md:px-6 lg:px-0 space-x-12 hidden md:flex">
        <div
          className={`swiper-prev-logos group text-white bg-cinnabar-500/75 hover:bg-cinnabar-500 cursor-pointer w-12 h-14 grid place-items-center font-bold text-3xl`}
        >
          <BsChevronLeft />
        </div>
        <Swiper
          className="lg:md:container mx-6 md:mx-auto h-full"
          spaceBetween={72}
          navigation={{
            nextEl: ".swiper-next-logos",
            prevEl: ".swiper-prev-logos",
          }}
          slidesPerView={`auto`}
          loop={true}
          onSlideChange={(e) => {}}
        >
          {logos.map((slide, i) => {
            return (
              <SwiperSlide
                key={slide.id || i}
                className="relative self-center w-[auto!important]"
              >
                <Image
                  image={slide}
                  className={`flex items-center w-auto max-w-[140px] max-h-20`}
                  imgStyle={{
                    objectFit: "contain",
                    objectPosition: "50% 50%",
                  }}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div
          className={`swiper-next-logos group text-white bg-cinnabar-500/75 hover:bg-cinnabar-500 cursor-pointer w-12 h-14 grid place-items-center font-bold text-3xl`}
        >
          <BsChevronRight />
        </div>
      </div>
      <div className="flex px-6 w-full gap-4 justify-between flex-wrap md:hidden">
        {logos.map((slide, i) => {
          return (
            <Image
              image={slide}
              className={`flex items-center w-auto max-w-[140px] max-h-20`}
              imgStyle={{
                objectFit: "contain",
                objectPosition: "50% 50%",
              }}
            />
          );
        })}
      </div>
    </>
  );
};

export default ClientLogos;
